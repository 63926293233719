import PropTypes from "prop-types";
import { memo } from "react";
import { FormattedMessage } from "react-intl";
import Paragraphs from "app/pages/.shared/Paragraphs";
import "./TravelDocuments.scss";

const TravelDocuments = ({ description }) => {
	return (
		<div className="travel-documents">
			<h4 className="travel-documents__headline">
				<FormattedMessage id="useful.information.travel.doc.headline" />
			</h4>
			<div className="travel-documents__description">
				<Paragraphs paragraphs={description} enableHTMLFormatting={true} />
			</div>
		</div>
	);
};

TravelDocuments.propTypes = {
	description: PropTypes.arrayOf(PropTypes.string),
};

TravelDocuments.defaultProps = {
	description: [],
};

export default memo(TravelDocuments);
